.col-active {
  width: 66.66667%;
}

.col,
.col-1,
.col-8 {
  transition: all 0.4s ease-in-out;
}

.componenteStatus {
  // max-width: 1350px;
  text-align: center;
  margin: 0 auto;
  padding: 0 15px;
}
.componenteStatus h2 {
  font-weight: 600;
  color: $branco;
  margin: 0;
}
.componenteStatus p {
  color: $branco;
  margin: 0;
}
.componenteStatus .col-1 {
  flex: 1 1 10.33333%;
  max-width: 11.33333%;
}

.conteudoComponente {
  width: 120px;
  height: 140px;
  align-items: center;
}

.conteudoComponente h3{font-family: 'Roboto Condensed', sans-serif; font-weight: bold; font-size: 18px; color: #FFF;}

.colorOFF {
  opacity: 0.3;
  cursor: default;
}
.colorOFF h2 {
  color: $preto;
}
.colorOFF p {
  color: $preto;
  font-weight: 600;
}

.line {
  transition: all 0.4s ease-in-out;
  border-top: 1px solid $cinza4;
  max-width: 800px;
  width: 80%;
  margin: 20px 19.7%;
}
.seta-sub-menu {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  left: -1px;
  top: 30px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.custom-sub-status .col {
  border-radius: 0px 0 0 0px;
  cursor: pointer;
}
.custom-sub-status .col:last-child {
  border-radius: 0px 10px 10px 0px;
}
.custom-sub-status .col:nth-child(1) .sombra {
  opacity: 0;
}

//primeiro item da lista
.custom-sub-status .col:nth-child(1) {
  background-color: $cor0;
  border-radius: 10px 0 0 10px;
}
.custom-sub-status .col:nth-child(2) .seta-sub-menu {
  border-left: 20px solid $cor0;
}
.line-custom-1 {
  transition: all 0.4s ease-in-out;
  border-top: 2px solid $cor0;
  max-width: 600px;
  width: 60%;
  margin: 20px 0;
}

//segundo item da lista
.custom-sub-status .col:nth-child(2) {
  background-color: $cor1;
}
.custom-sub-status .col:nth-child(3) .seta-sub-menu {
  border-left: 20px solid $cor1;
}
.line-custom-2 {
  transition: all 0.4s ease-in-out;
  border-top: 2px solid $cor1;
  max-width: 600px;
  width: 60%;
  margin: 20px 0;
}

//terceiro item da lista
.custom-sub-status .col:nth-child(3) {
  background-color: $cor2;
}
.custom-sub-status .col:nth-child(4) .seta-sub-menu {
  border-left: 20px solid $cor2;
}

//quarto item da lista
.custom-sub-status .col:nth-child(4) {
  background-color: $cor1-1;
}
.custom-sub-status .col:nth-child(5) .seta-sub-menu {
  border-left: 20px solid $cor1-1;
}
.line-custom-3 {
  transition: all 0.4s ease-in-out;
  border-top: 2px solid $cor2;
  max-width: 600px;
  width: 60%;
  margin: 20px 0;
}

//quinto item da lista
.custom-sub-status .col:nth-child(5) {
  background-color: $cor1-2;
}
.custom-sub-status .col:nth-child(6) .seta-sub-menu {
  border-left: 20px solid $cor1-2;
}
.line-custom-4 {
  transition: all 0.4s ease-in-out;
  border-top: 2px solid $cor1-2;
  max-width: 600px;
  width: 60%;
  margin: 20px 0;
}

//sexto item da lista
.custom-sub-status .col:nth-child(6) {
  background-color: $cor1-3;
}
.custom-sub-status .col:nth-child(7) .seta-sub-menu {
  border-left: 20px solid $cor1-3;
}
.line-custom-5 {
  transition: all 0.4s ease-in-out;
  border-top: 2px solid $cor1-3;
  max-width: 600px;
  width: 60%;
  margin: 20px 0;
}

.line-custom-6 {
  transition: all 0.4s ease-in-out;
  border-top: 2px solid $cor1-3;
  max-width: 600px;
  width: 60%;
  margin: 20px 0;
}

//setimo item da lista
.custom-sub-status .col:nth-child(7) {
  background-color: $cor3;
}
.custom-sub-status .col:nth-child(8) .seta-sub-menu {
  border-left: 20px solid $cor3;
}


.line-custom-7 {
  transition: all 0.4s ease-in-out;
  border-top: 2px solid $cor3;
  max-width: 600px;
  width: 60%;
  margin: 20px 0;
}

//oitavo item da lista
.custom-sub-status .col:nth-child(8) {
  background-color: $cor4;
}
.custom-sub-status .col:nth-child(9) .seta-sub-menu {
  border-left: 20px solid $cor4;
}

.line-custom-8 {
  transition: all 0.4s ease-in-out;
  border-top: 2px solid $cor4;
  max-width: 600px;
  width: 60%;
  margin: 20px 0;
}

//nono item da lista
.custom-sub-status .col:nth-child(9) {
  background-color: $cor5;
}
.custom-sub-status .col:nth-child(10) .seta-sub-menu {
  border-left: 20px solid $cor5;
}

.line-custom-9 {
  transition: all 0.4s ease-in-out;
  border-top: 2px solid $cor5;
  max-width: 600px;
  width: 60%;
  margin: 20px 0;
}

//decimo item da lista
.custom-sub-status .col:nth-child(10) {
  background-color: $cor6;
}
.custom-sub-status .col:nth-child(11) .seta-sub-menu {
  border-left: 20px solid $cor6;
}

.line-custom-10 {
  transition: all 0.4s ease-in-out;
  border-top: 2px solid $cor6;
  max-width: 600px;
  width: 60%;
  margin: 20px 0;
}

//decimo primeiro item da lista
.custom-sub-status .col:nth-child(11) {
  background-color: $cor7;
}
.custom-sub-status .col:nth-child(12) .seta-sub-menu {
  border-left: 20px solid $cor7;
}

.line-custom-11 {
  transition: all 0.4s ease-in-out;
  border-top: 2px solid $cor7;
  max-width: 600px;
  width: 60%;
  margin: 20px 0;
}

//////////////////////////////
.naoFaturado {
  background-color: $cor0;
  border-radius: 10px 0 0 10px;
  cursor: pointer;
}
.auditoria {
  background-color: $cor1;
  border-radius: 10px 0 0 10px;
  cursor: pointer;
}
.naoFaturado + .auditoria {
  border-radius: 0px 0 0 0px;
}
.iconeAuditoria {
  background: url('./img/icon_auditoria_WHITE.svg') no-repeat center;
  height: 40px;
  padding-right: 30px;
}
.iconeAuditoriaOFF {
  background: url('./img/icon_auditoria_BLACK.svg') no-repeat center;
  height: 40px;
}
.lineAuditoria {
  transition: all 0.4s ease-in-out;
  border-top: 2px solid $cor1;
  max-width: 600px;
  width: 60%;
  margin: 20px 0;
}

.textoAtendimento{
  font-size: 9px !important;

}

.pendente {
  background-color: $cor2;
  cursor: pointer;
}
.iconePendente {
  background: url('./img/icon_pendente_WHITE.svg') no-repeat center;
  height: 40px;
  padding-right: 30px;
}
.iconePendenteOFF {
  background: url('./img/icon_pendente_BLACK.svg') no-repeat center;
  height: 40px;
}

.setaPendente {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  left: -1px;
  top: 30px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid $cor1;
}
.setaEmAuditoria {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  left: -1px;
  top: 30px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid $cor0;
}
.linePendente {
  transition: all 0.4s ease-in-out;
  border-top: 2px solid $cor2;
  max-width: 600px;
  width: 60%;
  margin: 20px 0;
}

.faturamento {
  background-color: $cor3;
  cursor: pointer;
}
.iconeFaturamento {
  background: url('./img/icon_faturado_WHITE.svg') no-repeat center;
  height: 40px;
  padding-right: 30px;
}
.iconeFaturamentoOFF {
  background: url('./img/icon_faturado_BLACK.svg') no-repeat center;
  height: 40px;
}
.setaFaturamento {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  left: -1px;
  top: 30px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid $cor2;
}
.lineFaturamento {
  transition: all 0.4s ease-in-out;
  border-top: 2px solid $cor3;
  max-width: 600px;
  width: 60%;
  margin: 20px 0;
}

.glosado {
  background-color: $cor4;
  cursor: pointer;
}
.iconeGlosado {
  background: url('./img/icon_glosado_WHITE.svg') no-repeat center;
  height: 40px;
  padding-right: 30px;
}
.iconeGlosadoOFF {
  background: url('./img/icon_glosado_BLACK.svg') no-repeat center;
  height: 40px;
}
.setaGlosado {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  left: -1px;
  top: 30px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid $cor3;
}
.lineGlosado {
  transition: all 0.4s ease-in-out;
  border-top: 2px solid $cor4;
  max-width: 600px;
  width: 60%;
  margin: 20px 0;
}

.repassado {
  background-color: $cor5;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
}
.iconeRepassado {
  background: url('./img/icon_repassado_WHITE.svg') no-repeat center;
  height: 40px;
  padding-right: 30px;
}
.iconeRepassadoOFF {
  background: url('./img/icon_repassado_BLACK.svg') no-repeat center;
  height: 40px;
}
.setaRepassado {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  left: -1px;
  top: 30px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid $cor4;
}
.lineRepassado {
  transition: all 0.4s ease-in-out;
  border-top: 2px solid $cor5;
  max-width: 600px;
  width: 60%;
  margin: 20px 0;
}

.iconeFechar {
  background: url('./img/icon_fechar_WHITE.svg') no-repeat center;
  height: 16px;
  align-self: flex-start;
  margin-top: 20px;
}

.sombra {
  height: 26px;
  position: absolute;
  left: -20px;
  top: -20px;
  opacity: 0.1;
}

.horizonScrollDesktop {
  flex-wrap: nowrap;
  left: 0%;
  position: relative;
  transition: all 0.2s ease-in-out;
}
// Posição ao arrastar
.horizonScrollDesktop.scroll01 {
  left: 0%;
}
.horizonScrollDesktop.scroll02 {
  left: 0%;
}

// Ajuste de posição ao abrir
.horizonScrollDesktop.scrollAuditoriaON {
  left: 0%;
}
.horizonScrollDesktop.scrollPendenteON {
  left: 0%;
}

