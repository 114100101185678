/* PESQUISA */
.icone-pesquisa {
  mask-image: url(./img/icon_searchOpen.svg);
  -webkit-mask-image: url(./img/icon_searchOpen.svg);
  mask-repeat: no-repeat;
  background-color: $cinza4;
  width: 40px;
  margin: 4px 0px;
}

.blocoPontas {
  padding: 0px;
}
.bloco-pesquisa {
  position: relative;
  z-index: 2;
}
.temFiltro .icone-pesquisa {
  background-color: $cor4;
}

.corpo-pesquisa {
  margin-top: -15px;
  position: relative;
  z-index: 1;
  // background-color: $cinza1;
}

.btn-menor {
  // padding: 0px 10px;
}
.btn-menor span {
  margin-right: 5px;
}

.corpo-pesquisa .collapse {
  // background-color: $cinza1;
  margin-top: -15px;
  animation: fade 0.1s forwards;
}
// .corpo-pesquisa .defaultSelect .react-select__control {
//   padding-left: 30px;
// }
.corpo-pesquisa .barra-pesquisa .form-row {
  margin-left: -60px;
  margin-right: -10px;
}
.corpo-pesquisa .barra-pesquisa .input-group {
  flex-wrap: nowrap;
  justify-content: space-between;
}
.corpo-pesquisa .barra-pesquisa .input-group .form-control {
  width: 270px;
}
.corpo-pesquisa .barra-pesquisa .input-group .form-control,
.corpo-pesquisa .barra-pesquisa .defaultSelect .react-select__control,
.corpo-pesquisa .barra-pesquisa .defaultSelect .input-group-append .button,
.corpo-pesquisa .barra-pesquisa .defaultSelect .input-group-append .btn {
  // border: none;
}

.corpo-pesquisa .form-group {
  margin-bottom: 0px;
}

@keyframes fade {
  from {
    background-color: transparent;
  }
  to {
    background-color: $cinza1;
  }
}
@keyframes alpha {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.barra-pesquisa {
  animation: alpha 1s forwards;
}
.corpo-pesquisa .collapsing {
  // margin-top: -15px;
  background-color: transparent;
  // padding: 25px 20px;
  transition: height 0.35s ease;
}
.collapse ~ .barra-dotted,
.collapsing ~ .barra-dotted {
  display: none;
}

.collapse > .barra-pesquisa,
.collapsing > .barra-pesquisa {
  padding: 28px 10px 24px 44px;
}

// .barra-pesquisa::before {
//   content: '';
//   display: inline-block;
//   vertical-align: middle;
// }

.collapse.show ~ .barra-dotted {
  display: block;
  position: relative;
  height: 1px;
  width: 93%;
  margin: auto;
}
.barra-dotted .barra {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, transparent 50%, $cinza4 50%);
  background-size: 15px 100%;
  z-index: -1;
}
td .avatarCustom {
  z-index: 0;
}
.searchFilter {
  position: relative;
  min-width: 250px;
  margin-right: 5px;
}
  .searchFilter .react-select__control {
    padding-left: 30px;
  }
.searchFilterEnd {
  min-width: 250px;
  margin-left: 5px;
  margin-right: 50px;
}
  .searchFilterEnd .react-select__control {
    padding-right: 35px;
  }

/* FIM PESQUISA */

// ACTION-LINE
.action-line {
  font-family: 'Muli', sans-serif;

  min-height: 70px;
  // padding: 15px;
}
.action-line .labelInfoGrid {
  font-size: 12px;
  margin-left: 20px;
}
.action-line .btDefaultGrid {
  margin: 20px 10px;
  z-index: 100;
}
.action-line .labelInfoAction {
  font-size: 12px;
  font-weight: 700;
  margin-left: 10px;
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // max-width: 50%;
}
.action-topo-tabela {
  background-color: $cinza1;
}

.action-line.flutuante {
  position: static;
  top: calc(70px - 1.5rem);
  z-index: 1000;
  left: 15px;
  width: calc(100% - 30px);
  background-color: transparent;
}

.flutuante.collapse,
.flutuante.collapsing {
  position: fixed;
  top: 129px;
  z-index: 1000;
  width: calc(100% - 30px);
  margin: 0px;
  box-shadow: 1px 10px 10px 0px rgba(0, 0, 0, 0.15);
}

.boxAction {
  width: 100%;
  min-height: 50px;
  background-color: $branco;
  border-radius: 2px;
  padding: 9px;
  margin: 12px 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  // border: 1px solid $cinza3;
  // z-index: 100;
}
// FIM ACTION-LINE

// TABELA
.table {
  color: $preto;
  background-color: $branco;
  font-family: 'Nunito', sans-serif;
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: 700;
}
.form-check-input {
  position: initial;
  margin-top: 0;
  margin-left: 0;
}
.table-bordered thead th,
.table-bordered thead td {
  text-align: center;
  border-bottom-width: 1px;
  padding: 10px;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid $cinza3;
}
.table-bordered thead tr:hover {
  cursor: pointer;
}
.table-bordered tbody tr:hover {
  background-color: $cinza1;
  cursor: pointer;
}
.table-bordered tbody tr.checked {
  background-color: $cinza1;
}
.table-bordered tbody tr:last-child {
  border-bottom: 2px solid $cinza3;
}

.table thead th {
  font-weight: 800;
  background-color: $cinza2;
  // padding: 10px 20px;
}
.table th,
.table td {
  vertical-align: middle;
}

th label {
  margin: 0 0 2px 0;
}
thead th.classChecked {
  background-color: $cor4;
  border-bottom-color: $cor6;
}
td label {
  margin: 0 0 2px 0;
}
td.classChecked {
  background-color: $cor4;
  border-bottom-color: $cor6;
}

.table .classChecked {
  background-color: $cor4;
}
.label-resultados {
  font-size: 10px;
  color: $cinza5;
  font-style: italic;
}
td .avatarCustom {
  width: 32px;
  height: 32px;
  font-size: 16px;
  margin: -10px auto;
}

// HEADERS CUSTOM
.table th.headerCenter {
  padding: 0 14px;
  vertical-align: middle;
  text-align: center;
  position: relative;
}
.table th.headerLeft {
  padding: 10px 14px;
  vertical-align: middle;
  text-align: left;
  position: relative;
}
.ascendente {
  height: 8px;
  transform: rotate(-90deg);
  position: absolute;
  right: 8px;
  bottom: 6px;
}
.descendente {
  height: 8px;
  transform: rotate(90deg);
  position: absolute;
  right: 8px;
  bottom: 6px;
}

// COLUNAS CUSTOM
.table td.colunaCheck {
  width: 45px;
  padding: 10px 0;
  text-align: center;
}
.table td.colunaFoto {
  width: 45px;
  padding: 10px 0;
  text-align: left;
}
.table td.colunaMin {
  width: 90px;
  padding: 0 14px;
  text-align: center;
}
.table td.colunaPeq {
  width: 135px;
  padding: 0 14px;
  text-align: center;
}
.table td.colunaMed {
  width: 200px;
  padding: 0 14px;
  text-align: center;
}
.table td.colunaGra {
  padding: 0 14px;
  text-align: left;
}
// .table td.colunaPerfil {
//   width: 135px;
//   padding: 10px 0;
//   text-align: center;
// }
// .table td.colunaConvenios {
//   width: 135px;
//   padding: 10px 0;
//   text-align: center;
// }

th label {
  margin: 0 14px 14px 0;
}
td label {
  margin: 0 14px 14px 0;
}

.container-com-topo .action-line .boxAction label {
  margin: 0px 30px 14px 0px;
}
.container-com-topo .action-line .boxAction .labelInfoAction {
  margin-left: 4px;
}
.boxAction a:not([href]) {
  color: #00cb7d;
  cursor: pointer;
}

.boxAction a:not([href]):hover {
  color: #00e392;
  cursor: pointer;
}

.adicionarPesquisa {
  position: absolute;
  right: 10px;
  top: 38px;
}
@media (max-width: 1024px) {
  .collapse > .barra-pesquisa,
  .collapsing > .barra-pesquisa {
    padding: 28px 10px 24px 20px;
  }
  .searchFilter {
    min-width: 230px;
  }
  .searchFilterEnd {
    min-width: 230px;
    margin-right: 10px;
  }
  .adicionarPesquisa {
    top: 33px;
  }
}
@media (max-width: 767px) {
  .corpo-pesquisa .barra-pesquisa .form-row {
    margin-left: -25px;
  }
  .corpo-pesquisa .barra-pesquisa .form-row {
    margin-left: -8px;
    margin-right: 13px;
  }
  .corpo-pesquisa .barra-pesquisa .defaultSelect {
    margin-left: -5px;
    margin-right: -5px;
  }
  .corpo-pesquisa .barra-pesquisa .defaultSelect .input-group-append .btn {
    z-index: 0;
  }
  .react-code4-table table,
  .react-code4-table thead,
  .react-code4-table tbody,
  .react-code4-table th,
  .react-code4-table td,
  .react-code4-table tr {
    display: block;
  }
  .react-code4-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .react-code4-table td {
    border: none;
    border-bottom: 1px solid $cinza3;
    position: relative;
    padding-left: 36% !important;
    white-space: normal;
    text-align: center;
  }
  .react-code4-table td:after {
    font-family: 'Nunito', sans-serif;
    font-weight: 800;
    font-size: 12px;
    position: absolute;
    top: 10px;
    left: 10px;
    width: 30%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    text-transform: none;
    color: $preto;
    background-color: $cinza2;
    border-right: 1px solid $cinza3;
    padding: 0 10px;
  }
  .react-code4-table td:first-child {
    // padding-top: 5px;
  }
  .react-code4-table td:last-child {
    padding-bottom: 5px;
  }
  .react-code4-table td:before {
    top: 5px;
  }
  .react-code4-table td .colunaDestaque:after {
    top: 15px;
  }
  .react-code4-table td:before {
    // content: attr(data-title);
  }
  .react-code4-table td:after {
    content: attr(data-title);
    padding-top: 12px;
    height: 100%;
    text-align: center;
    margin: -10px;
  }
  .react-code4-table .table tbody tr {
    border-top: 1px solid $cinza3;
  }
  .react-code4-table .table tbody tr td {
    border: none;
    padding: 3px;
  }
  .temSinal .react-code4-table .table tbody tr td:last-child {
    display: inline-flex;
    padding-left: 0px !important;
    vertical-align: sub;
  }
  .temSinal .react-code4-table .table tbody tr td:nth-last-child(2) {
    display: inline-flex;
  }

  .react-code4-table .table tbody tr:first-child {
    border-top: 0px solid $cinza3;
  }
  .react-bs-container-header .table thead {
    display: none;
  }

  // COLUNAS CUSTOM
  .table td.colunaCheck {
    width: 100%;
    text-align: center;
    padding: 10px 20px;
  }
  .table td.colunaFoto {
    width: 100%;
    text-align: center;
    padding: 10px 20px;
  }
  .table td.colunaFoto .avatarCustom {
    width: 50px;
    height: 50px;
    margin: 0 auto;
  }
  .table td.colunaMin,
  .table td.colunaPeq,
  .table td.colunaMed,
  .table td.colunaGra {
    width: 100%;
    text-align: center;
    padding: 10px 20px;
  }
  // .table td.colunaPerfil {
  //   width: 100%;
  //   text-align: center;
  //   padding: 10px 20px;
  // }
  // .table td.colunaConvenios {
  //   width: 100%;
  //   text-align: center;
  //   padding: 10px 20px;
  // }

  .action-line .labelInfoAction.half {
    width: 50%;
  }
  tbody tr {
    border-bottom: 2px solid $preto;
  }
  .container-com-topo .action-line .boxAction label {
    margin: 0px 30px 6px 0px;
  }
  .action-line .labelInfoGrid {
    margin-left: 0;
    margin-top: 20px;
  }
  .action-line .boxAction .labelInfoAction {
    margin: 10px 0;
    white-space: pre-wrap;
    max-width: 90%;
    text-align: center;
  }
  .action-line .labelInfoAction label {
    font-size: 12px;
  }
  .boxAction a {
    white-space: nowrap;
  }
  .collapse > .barra-pesquisa,
  .collapsing > .barra-pesquisa {
    padding: 28px 20px 40px 25px;
  }
  .searchFilter {
    max-width: 250px;
    margin: 5px auto;
  }
  .searchFilterEnd {
    width: 240px;
    margin: 0 auto 20px;
  }
  .adicionarPesquisa {
    position: inherit;
    text-align: center;
  }
}

// FIM TABELA
