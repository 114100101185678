.barra-lotes > .box-lote {
  height: initial;
  min-width: 240px;
}

.mt-neg-25 {
  margin-top: -20px;
}

.pagination-button {
  margin: 10px 15px 20px 15px;
}
