.center {
  display: block;
  margin: 0 auto;
}

.color-red {
  color: #c24040 !important;
}

.change-picture:hover {
  opacity: 1 !important;
}

.change-picture {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  margin-top: -50px;
  position: relative;
  width: 95px;
  background-color: rgb(219, 219, 219, 0.5);
  border-radius: 60px;
  color: black;
}

.text-change-picture {
  font-size: 14px;
  font-weight: 700;
}

.buttonImage {
  position: relative;
  display: flex;
}

.removeImage {
  display: flex;
  justify-content: center;
  position: absolute;
  border-radius: 20px;
  background-color: #ea3a45;
  top: 0;
  right: 0;
  margin: 0 10px;
  height: 40px;
  width: 40px;
  opacity: 0;
  transition: 0.5s ease;
}

.buttonImage:hover .removeImage {
  opacity: 1;
  transform: translate(0, 10px);
}

.icon-x {
  align-self: center;
  margin-left: 3px;
  color: #fff;
}

.changeImage {
  display: flex;
  justify-content: center;
  position: absolute;
  border-radius: 20px;
  background-color: #85cd63;
  bottom: 0;
  right: 0;
  margin: 0 10px;
  height: 40px;
  width: 40px;
  opacity: 0;
  transition: 0.5s ease;
}

.buttonImage:hover .changeImage {
  opacity: 1;
  transform: translate(0, -10px);
}

.icon-foto {
  align-self: center;
  margin-left: 3px;
  color: #fff;
}

@media (max-width: 1024px) {
  .removeImage {
    margin: -4px 6px;
    height: 24px;
    width: 24px;
  }
  .buttonImage .removeImage {
    opacity: 1;
    transform: translate(0, 10px);
  }
  .icon-x {
    font-size: 9px;
    margin-left: 0;
  }
  .changeImage {
    margin: -4px 6px;
    height: 30px;
    width: 30px;
  }
  .buttonImage .changeImage {
    opacity: 1;
    transform: translate(0, -10px);
  }
  .icon-foto {
    font-size: 14px;
    margin-left: 0;
  }
}
