.background-notification {
  height: 100vh;
  box-shadow: 0px 4px 20px #0000001c;
  background-color: $cinza2;
}

.container-notifications {
  max-width: 800px;
  padding: 80px 0 20px;
  position: relative;
}
.container-notifications div.notification {
  margin: 5px auto;
  background: #ffffff;
  border-radius: 10px;
  min-height: 70px;
  max-width: 600px;
  padding: 20px 24px 20px 10px;
  cursor: pointer;
}
.notification p.descricao {
  font-size: 14px;
}

p.qtdNotificacoes {
  margin: 10% 0 10px;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
}

h2.semNotificacoes {
  text-align: center;
  color: $cinza5;
  font-weight: 300;
  margin: 20% auto;
}

.max-hr-notifications {
  width: 800px;
  margin: 0 0 10px;
}

.icon-notification {
  height: 60px;
  position: absolute;
}

.backNotification {
  height: 17px;
  margin-bottom: 2px;
}

.avatarNotification {
  transform: scale(0.55);
  margin: auto;
}
// .avatarNotification img {
//   // transform: scale(0.55);
//   // width: 30px;
//   // height: 30px;
// }

.notificationOpened .container-com-topo {
  margin-top: 0px;
  transition: all 0.4s ease-in-out;
}
.notificationOpened .bodyNotification {
  overflow-y: auto;
  padding-top: 60px;
}

footer p {
  color: $cinza4 !important;
}

.list-usuario-item {
  border-bottom: 1px solid $cinza2;
  width: 100%;
}

.notification p{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 95%;
    margin: 0;
}

/* MOBILE */
@media (max-width: 768px) {
  .container-notifications {
    padding: 30px 0 20px;
  }
  .container-notifications div.notification {
    margin: 10px auto;
    max-width: 540px;
  }

  p.qtdNotificacoes {
    margin: 0 0 10px;
  }
  .max-hr-notifications {
    margin: auto;
    width: 600px;
  }
  .icon-notification {
    margin: 10px auto;
  }
}
@media (max-width: 575px) {
  .container-notifications {
    padding: 20px 0 20px;
  }
  .container-notifications div.notification {
    margin: 10px;
    padding: 20px;
  }

  p.qtdNotificacoes {
    margin: 0 0 10px;
    text-align: center;
  }
  .max-hr-notifications {
    margin: auto;
    width: 90%;
  }
  .icon-notification {
    display: flex;
    margin: 20px auto;
    position: relative;
  }

  .backNotification {
    height: 22px;
  }
}
