.item-historico {
  background-color: $cinza2;
  padding: 10px 15px;
  margin: 35px 0px;
}
.historico .status {
  font-weight: bold;
  line-height: 1.2;
  text-transform: capitalize;
}
.linkAnexo {
  color: #2b454e;
  font-size: 12px;
  text-decoration: underline;
  font-style: italic;
  background-color: transparent;
  padding: 0px;
}

.linkAnexo:hover {
  color: $cor6;
  outline: none;
}
.linkAnexo:focus {
  outline: none;
}

.linkAnexo span.icon-btn-anexar {
  width: 11px;
  margin-right: 3px;
  margin-left: 0px;
  margin-top: 9px;
}

.historico .avatarCustom {
  width: 20px;
  height: 20px;
  font-size: 12px;
}

.descricao-historico {
  padding-top: 5px;
  line-height: 1.2;
  margin-bottom: 0px;
}

.mw-20 {
  min-width: 20px;
}

.dados-historico:after {
  content: '';
  position: fixed;
  z-index: -1;
  top: 100px;
  bottom: 0;
  border-left: 2px dotted $cinza5;
}
