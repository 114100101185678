.btn-voltar {
  cursor: pointer;
}
  .btn-voltar img {
    width: 20px;
    margin: 40px 0 60px 40px;
  }

a.link-cinza {
  font-size: 12px;
  color: #999;
}

.inputSenha {
  max-width: 300px;
  margin: 0 auto 0;
  padding: 0;
}

.esqueciSenha {
  text-align: center;
  margin: 30px 0 53px;
}

/* MOBILE HEIGHT */

@media (max-height: 568px) {
  .btn-voltar img {
    margin: 40px 0 23px 40px;
  }
  .inputCPF {
    max-width: 200px;
    margin: 15px auto 63px;
  }
  .inputSenha {
    max-width: 200px;
    margin: auto;
  }
  .esqueciSenha {
    margin: 25px 0 36px;
  }
}
@media (min-height: 640px) and (max-height: 666px) {
  
}
@media (min-height: 667px) and (max-height: 730px) {

}
@media (min-height: 730px) and (max-height: 811px) {
 
}
@media (min-height: 812px) and (max-height: 1000px) {
 
}