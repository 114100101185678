.background-branco {
  border-radius: 60px;
  min-width: 115px;
  background-color: $branco;
  bottom: 0px;
  right: 0px;
}

.bloco-image-user {
  min-width: 100px;
  position: relative;
  cursor: pointer;
}
.bloco-image-user .avatar {
  position: relative;
  width: 100px;
  height: 100px;
}

.info-user p,
.info-user h2,
.info-user label {
  margin-bottom: 0px;
  margin-left: 10px;
}
.info-user p.txtCooperativa {
  font-weight: 600;
}
.info-user p.txtPerfil {
  color: $cinza5;
  margin-top: 2px;
}
.info-texto {
  color: $preto;
  font-size: 12px;
}
.icon-foto-user {
  background-color: $branco;
  position: absolute;
  bottom: 1px;
  right: -15px;
  width: 90px;
  height: 30px;
  border-radius: 20px;
}
.icon-foto-user img {
  height: 15px;
  position: absolute;
  right: 10px;
}

.icon-foto-user.photo img {
  height: 20px;
  position: absolute;
  right: 0px;
}

@media (max-width: 575px) {
  .info-user {
    text-align: center;
  }
  .info-user p {
    margin: 8px 0 0;
  }
  .info-user h2 {
    margin: 0 0 3px;
  }
}
