.box-lote-container {
  width: 100%;
}
.box-lote {
  // margin: 10px 5px;
  height: 160px;
  border-radius: $border-radius;
  background-color: $branco;
}
.box-lote.borda {
  border: 1px solid $cor4;
}

.box-lote .sub-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
  margin: 0;
}

.box-lote .avatarCustom {
  width: 32px;
  height: 32px;
  font-size: 16px;
  margin: -10px auto;
}

.slick-slider {
  background-color: $cinza1;
  padding: 5px 12px;
  height: 215px;
}

.slick-prev:before,
.slick-next:before {
  color: red;
}

.slick-list {
  margin: 0px 25px;
}
.slick-slide {
  margin: 10px 4px;
}
.slick-slider button {
  min-width: initial;
  max-width: initial;
}
.slick-next {
  right: 5px;
}

.slick-prev {
  left: 5px;
}

.footer-box-lote {
  border-top: 1px solid $cinza2;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 35px;
}

.footer-box-lote.verde {
  background-color: #00cb7d;
  border-top: 1px solid #00cb7d;
}
.footer-box-lote.verde .btn-link {
  color: #fff;
}
.w-35 {
  width: 35%;
}
.box-lote-info p,
.box-lote-info h2 {
  margin: 1px;
  line-height: 14px;
}
.box-lote .icon-lote {
  height: 30px;
}
.box-lote .avatar {
  height: 20px;
}
.next-icon,
.prev-icon {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: calc(50% - 15px);
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background-repeat: no-repeat;
}
.next-icon {
  right: 5px;
  top: calc(50% - 30px);
  background-image: url('./img/btn_voltar.svg');
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.prev-icon {
  left: 5px;
  background-image: url('./img/btn_voltar.svg');
}

@media (max-width: 575px) {
  .box-lote {
    width: 100%;
  }
}

