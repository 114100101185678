.textCKeditor {
  /*//Barra de ferramentas*/
  .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
    border: 1px solid #f0f5f9;
    border-radius: 20px !important;
    background-color: #fff;
    padding: 0 20px;
  }
  .ck.ck-toolbar .ck.ck-toolbar__separator {
    background-color: #f0f5f9;
  }

  /*//Conteúdo*/
  .ck.ck-content.ck-editor__editable {
    background-color: #fff;
    border-radius: 20px !important;
    min-height: 200px;
    border: 1px solid #f0f5f9 !important;
    margin: 10px 0 50px;
    padding: 10px 20px;
    font-size: 14px;
    }
    .ck.ck-content.ck-editor__editable:focus {
      border: 1px solid #e1e6eb;
      box-shadow: none;
    }
    .ck.ck-content.ck-editor__editable .ck-placeholder:before {
      color: #a9afb5;
      font-size: 14px;
      font-weight: 200;
    }
}

/*// Inválido*/
.textCKeditor.is-invalid .ck.ck-content.ck-editor__editable {
  border: 2px solid #ea3a45;
  padding-right: calc(1.5em + 0.75rem);
  /*background-image: url("src/img/invalid.svg");*/
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}