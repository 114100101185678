// TOAST GERAL
.redux-toastr .top-center .toast-sasc,
.redux-toastr .bottom-center .toast-sasc {
  width: 163%;
  left: -32%;
}
.redux-toastr .toastr.toast-sasc {
  opacity: 1;
  box-shadow: 2px 7px 15px 0px rgba(0, 0, 0, 0.08);
}
.redux-toastr .toastr.toast-sasc {
  min-height: 50px;
}

// Icone
.redux-toastr .toastr.toast-sasc .rrt-left-container {
  width: 50px;
  left: 30px;
}
.redux-toastr .toastr.toast-sasc .rrt-left-container .rrt-holder {
  width: 40px;
}
.toast-sasc .icon_error,
.icon_success,
.icon_pendencia,
.icon_warning,
.icon_info {
  padding: 5px 0 0;
  margin-left: 12px;
}

// Texto
.redux-toastr .toastr.toast-sasc .rrt-middle-container {
  font-family: 'Muli', sans-serif;
  font-weight: 400;
  font-size: 20px;
  padding: 15px 5px;
  margin-left: 70px;
  width: 78%;
}
.redux-toastr .toastr.toast-sasc .rrt-middle-container .rrt-title {
  font-weight: bold;
  color: $branco;
}

// Botão fechar
.redux-toastr .toastr.toast-sasc .close-toastr {
  font-size: 15px;
  color: $branco;
  opacity: 1;
  right: 0px;
}

// Barra de progressão
.redux-toastr .toastr.rrt-error.toast-sasc .rrt-progressbar,
.redux-toastr .toastr.rrt-success.toast-sasc .rrt-progressbar,
.redux-toastr .toastr.rrt-warning.toast-sasc .rrt-progressbar,
.redux-toastr .toastr.rrt-pendencia.toast-sasc .rrt-progressbar,
.redux-toastr .toastr.rrt-info.toast-sasc .rrt-progressbar {
  background-color: #0000000f;
}

// TOAST ERRO
// Cor principal
.redux-toastr .toastr.rrt-error.toast-sasc-erro {
  background-color: $vermelho;
  display: inline-table;
}
// .redux-toastr .toastr.rrt-error.toast-sasc-erro:hover {
//   box-shadow: none;
// }

// TOAST SUCCESS
// Cor principal
.redux-toastr .toastr.rrt-success.toast-sasc-success {
  background-color: $cor4;
  display: inline-table;
}
// .redux-toastr .toastr.rrt-success.toast-sasc-success:hover {
//   box-shadow: none;
// }

// TOAST WARNING
// Cor principal
.redux-toastr .toastr.rrt-warning.toast-sasc-warning {
  background-color: $amarelo;
  display: inline-table;
}
// .redux-toastr .toastr.rrt-warning.toast-sasc-warning:hover {
//   box-shadow: none;
// }

// TOAST PENDENCIA
// Cor principal
.redux-toastr .toastr.rrt-success.toast-sasc-pendencia {
  background-color: $cor2;
  display: inline-table;
}

.redux-toastr .toastr.rrt-pendencia.toast-sasc-pendencia:hover {
  box-shadow: none;
}

// TOAST INFO
// Cor principal
.redux-toastr .toastr.rrt-info.toast-sasc-info {
  background-color: $branco;
  color: $preto;
  display: inline-table;
}
.redux-toastr .toastr.rrt-info.toast-sasc-info .rrt-text,
.redux-toastr .toastr.rrt-info.toast-sasc-info .rrt-title {
  color: $preto;
}

.redux-toastr .toastr.toast-sasc.toast-sasc-info .close-toastr {
  color: $preto;
}
.redux-toastr .toastr.rrt-info.toast-sasc-info:hover {
  box-shadow: none;
}

// TOAST INFO BLACK
// Cor principal
.redux-toastr .toastr.rrt-info.toast-sasc-info-black {
  background-color: $cor1;
  display: inline-table;
}

.redux-toastr .toastr.rrt-pendencia.toast-sasc-pendencia:hover {
  box-shadow: none;
}

.redux-toastr .toastr.rrt-info.toast-sasc-info:hover {
  box-shadow: none;
}

// Mobile
@media (max-width: 575px) {
  .redux-toastr .top-center .toast-sasc,
  .redux-toastr .bottom-center {
    width: 100%;
    left: 0;
  }
  .redux-toastr .toastr.rrt-info.toast-sasc-info,
  .redux-toastr .toastr.rrt-error.toast-sasc-erro,
  .redux-toastr .toastr.rrt-pendencia.toast-sasc-pendencia,
  .redux-toastr .toastr.rrt-warning.toast-sasc-warning,
  .redux-toastr .toastr.rrt-success.toast-sasc-success {
    padding-top: 10px;
  }
  // Texto
  .redux-toastr .toastr.toast-sasc .rrt-middle-container {
    width: 68%;
    margin-left: 56px;
    bottom: 7px;
  }
  // Botão fechar
  .redux-toastr .toastr.toast-sasc .close-toastr {
    right: -30px;
  }
}
