body {
  overflow-y: scroll;
}
.notificacao {
  width: 15px !important;
}

// .section-form > div .form-control {
//   width: 100%;
//   border-color: $cinza2;
//   border-radius: 8px;
//   padding: 0px 10px;
// }
// .status-guia {
//   font-size: 16px;
//   font-family: "Roboto Slab", sans-serif;
//   font-weight: lighter;
// }
// .h-10 {
//   height: 10px;
//   background-color: $branco;
// }
// .section-form > div .form-control.destaque {
//   border-color: $cinza5;
// }
// .section-form .no-border-right {
//   border-right: none;
// }
// .section-form > div:last-child {
//   border-right: 0px solid $cinza2;
// }

.visualizar {
  width: 27px;
  height: 24px;
  margin-bottom: 1%;
}

.arquivo {
  width: 15%;
}

.edit-img {
  width: 13%;
  height: 15%;
}

.guia p {
  color: #000000;
}

.card-header.b-bottom{
  border-bottom: 2px solid $cinza2;
}



/* MOBILE */

@media (max-width: 1024px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 767px) {
  .background-guia .borda-larga {
    display: block !important;
  }

  .text-mobile-center {
    text-align: center;
  }
}

@media (max-width: 575px) {
  .background-guia .borda-larga {
    display: block !important;
  }

  .text-mobile-center {
    text-align: center;
  }

  // Guia - botão Editar mobile
  .accordion > .card .card-header {
    height: 180px;
  }
  .ml-auto, .mx-auto {
    margin-top: 10px;
    margin-left: 0 !important;
  }
  span.icon-seta-baixo {
    margin-top: 25px;
    margin-bottom: 5px;
  }

}
