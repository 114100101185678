@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Muli:400,400i,600,600i,700,700i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Nunito:400,400i,600,600i,700,700i,800,800i&display=swap');
/* Criar aqui as customizações */
$theme-colors: (
  'primary': #00cb7d,
  'secondary': #2b454e,
  'danger': #ff7676,
  'warning': #d4dd66,
  'light': #ffffff,
);

$vermelho: #ff7676;
$vermelho1: #d1333d;
$amarelo: #d4dd66;
$azul: #3898d0;

//   Escala de cor padrão do sistema
$cor0: #192c35; //Auditor e Auditoria
$cor1: #2b454e; //Auditor e Auditoria

$cor1-1: #3d7579;
$cor1-2: #358489;
$cor1-3: #2ea3ac;
$cor1-4: #269499;

$cor2: #3c6669; //Protocolo e Pendencia
$cor3: #1f8e86; //Consultorio e Faturado
$cor4: #00cb7d; //Cooperado e Glosado
$cor5: #58e26d; //Administrador e Repassado
$cor6: #0cbc78;
$cor7: #3fd89d;

//   Monocromaticas
$branco: #ffffff;
$cinza1: #f8f8f8;
$cinza2: #eff3f4;
$cinza3: #e0e0e0;
$cinza4: #cccccc;
$cinza5: #999999;
$preto: #000000;

$body-bg: $cinza2;
$border-radius: 10px;

$font-family-sans-serif: 'Muli', sans-serif;
