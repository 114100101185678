.background-login {
  position: fixed;
  background-color: $preto;
  background: url("./Background-login.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

}
.captcha{
text-align: center;

}
.captcha div{
  display: inline;
}
.logoInicial {
  position: absolute;
  transition: all 0.4s ease-in-out;
  margin: 90px 0 0;
  text-align: center;
  left: 7%;
  height: 44px;
}
  .logoInicial.menor {
    transition: all 0.4s ease-in-out;
    margin: 40px 0 0;
    text-align: center;
    left: 28%;
    height: 23px;
  }

.txtInicial {
  text-align: center;
  max-width: 230px;
  margin: 74px auto auto;
}

.inputCPF {
  max-width: 300px;
  margin: 13px auto 5px;
}


/* MEDIA MOBILE */

  @media (max-width: 1024px) {
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
  }

  @media (max-width: 575px) {
    
  }
  @media (max-width: 414px) {
    .logoInicial {
      left: 0;
      height: 47px;
    }
  }

  @media (max-width: 375px) {
    .logoInicial {
      left: 0;
      height: 42px;
    }
    .logoInicial.menor {
      left:22%;
    }
  }

  @media (max-width: 360px) {

  }
  
  @media (max-width: 320px) {
    .logoInicial {
      left: 0;
      height: 36px;
    }
      .logoInicial.menor {
        left:22%;
      }
  
    .txtInicial {
      margin: 75px auto auto;
    }
    .inputCPF {
      max-width: 250px;
      margin: 15px auto 80px;
    }
  }